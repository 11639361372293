// Import the cookie library
//import Cookies from 'lib/js.cookie.min.mjs'

// The Main Site Object
let Site = {

    submenuBtn : document.getElementById('collections'),
    menu       : document.getElementById('menu'),
    header     : document.querySelector('.header'),

    // Init Function
    liftoff : () => {

        Site.showSubMenuOnClick()

    },

    // On click show the collections submenu
    showSubMenuOnClick : () => {

        Site.submenuBtn.addEventListener('click' , () => {
            Site.menu.classList.add('submenu-open')
            Site.header.addEventListener('mouseleave' , Site.hideMenuOnMouseOut);
        })

    },

    // Hide the menu on mouse out
    hideMenuOnMouseOut : () => {
        Site.menu.classList.remove('submenu-open')
    },



}

// Launch The Script
Site.liftoff()
